<template>
    <div>
        <b-card no-body class="mb-5">
            <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
                    {{ $t('advanced_filter') }}
                </b-button>
            </b-card-header>
            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row mb-5">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="customer">{{$t('customer')}}</label>
                                <multiselect id="customer" v-model="customer" :placeholder="$t('customer')"
                                             label="name"
                                             track-by="id"
                                             :options="customers"
                                             :multiple="false"
                                             :taggable="false"
                                             :show-labels="false"
                                             :show-no-options="false"
                                             :show-no-results="false"
                                             @search-change="getCustomers($event)">
                                </multiselect>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="from_date">{{$t('inventory_transaction_report.from_date')}}</label>
                                <input type="date" id="from_date" v-model="filters.from_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label for="to_date">{{$t('inventory_transaction_report.to_date')}}</label>
                                <input type="date" id="to_date" v-model="filters.to_date" class="form-control">
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('group_by')}}</label>
                                <select name="" id="" v-model="filters.filter_by" class="custom-select">
                                    <option value="status">{{$t('status')}}</option>
                                    <option value="client">{{$t('client')}}</option>
                                    <option value="due_date">{{$t('due_date')}}</option>
                                </select>
                            </div>

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('status')}}</label>
                                <select name="" id="f_status" v-model="filters.status" type="text" class="custom-select">
                                    <option value="">{{$t('all')}}</option>
                                    <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.title }}</option>
                                </select>
                            </div>

                            <!-- <div class="col-md-3">
                                <label>{{$t('currency')}}</label>
                                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                                    <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                                </select>
                            </div> -->

                            <div class="form-group d-inline-flex col-md-6 mt-10">
                                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                    {{$t('display_report')}}
                                </button>
                                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                                    <i class="fas fa-trash-restore"></i> {{$t('reset_search')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </b-card-body>
            </b-collapse>
        </b-card>

        <!--begin::customer-->
        <div class="card card-custom mt-5">

            <div class="card-body">

                <div class="col-md-12 mb-5">
                    <div class="m-form m-form--fit m--margin-bottom-20">
                        <div class="row">
                            <div class="col-12 mt-10" id="summaryTableTitle">
                                <!-- Clients Installments - Group By Client -->
                                <h4 class="text-center">{{ $t('customer_installment_report.clients_installments_group_by_client')}}</h4>
                                <h5 class="text-center"><span v-if="filters.from_date">{{ $t('from') }} 
                                    {{filters.from_date}}</span> <span v-if="filters.to_date">{{ $t('to') }} {{filters.to_date}}</span>
                                </h5>
                            </div>
                            <div class="col-12 mt-10 d-flex justify-content-end">
                                <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                                    <button class="dropdown-item" @click="convertTableToExcelReport">
                                        <i class="la la-file-excel"></i>{{$t('excel')}}
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails', 'print')">
                                        <span><i class="fa fa-print"></i> <span>{{$t('print')}}</span></span>
                                    </button>
                                    <button class="dropdown-item" @click="printData('summaryTableDetails', 'pdf')">
                                        <span><i class="fa fa-file-pdf"></i> <span>{{$t('export_pdf')}}</span></span>
                                    </button>
                                </b-dropdown>
                            </div>
                            <div class="col-12 mt-10">
                                <div class="table-responsive">
                                    <table class="table table-bordered" id="summaryTableDetails">
                                        <thead>
                                        <tr id="myRow">
                                            <th>#</th>
                                            <th>{{$t('customer_installment_report.client')}}</th>
                                            <th>{{$t('customer_installment_report.email')}}</th>
                                            <th>{{$t('customer_installment_report.address')}}</th>
                                            <th>{{$t('customer_installment_report.mobile')}}</th>
                                            <th>{{$t('customer_installment_report.phone')}}</th>
                                            <th>{{$t('customer_installment_report.due_date')}}</th>
                                            <th>{{$t('customer_installment_report.status')}}</th>
                                            <th>{{$t('customer_installment_report.total_amount')}}</th>
                                            <th>{{$t('customer_installment_report.paid_amount')}}</th>
                                            <th>{{$t('customer_installment_report.remaining_amount')}}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-for="(row, index) in data">
                                            <tr :key="'c'+index">
                                                <td colspan="11">{{row.label}}</td>
                                            </tr>
                                            <tr v-for="(row2, index2) in row.details" :key="'c'+getRandom()+index2">
                                                <td>{{index2 + 1}}</td>
                                                <td>{{row2.customer_name}}</td>
                                                <td>{{row2.email}}</td>
                                                <td>{{row2.address}}</td>
                                                <td>{{row2.mobile}}</td>
                                                <td>{{row2.phone}}</td>
                                                <td>{{row2.installment_due_date}}</td>
                                                <td>{{row2.status_name}}</td>
                                                <td>{{row2.amount}}</td>
                                                <td>{{row2.paid_amount}}</td>
                                                <td>{{row2.unpaid_amount}}</td>
                                            </tr>
                                            <tr :key="'d'+index">
                                                <td colspan="10">{{$t('total')}}</td>
                                                <td>{{row.total.sum_total}}</td>
                                            </tr>
                                        </template>

                                        </tbody>
                                        <tfoot>
                                        <tr v-if="dataTotal">
                                            <td colspan="8">{{$t('total')}}</td>
                                            <th>{{ dataTotal.sum_total}}</th>
                                            <th>{{ dataTotal.sum_paid}}</th>
                                            <th>{{ dataTotal.sum_unpaid}}</th>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 text-center" v-if="page">
                    <button class="btn btn-warning" @click="loadMore">
                        <p class="mb-0">
                            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
                            {{$t('load_more')}}
                        </p>
                    </button>
                </div>
                <!-- End Filter -->

            </div>
        </div>
      <div class="selector-export" id="selectorExport"></div>
        <!--end::customer-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import TableToExcel from "@linways/table-to-excel";
    import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

    export default {
        name: "clients-installment-report",

        data() {
            return {
                mainRoute: '/reports/customer-report/installments',
                mainRouteDependency: 'base/dependency',
                inventories: [],
                data: [],
                dataTotal: [],
                brands: [],
                categories: [],
                customers: [],
                daily_sales: [],
                currencies: [],
                data_more_details: [],
                details_total_sum: [],
                status_list: [],

                filters: {
                    item_id: null,
                    invoice: null,
                    brand_id: null,
                    customer_id: null,
                    employee_id: null,
                    category_id: null,
                    inventory_id: null,
                    currency_id: null,
                    filter_by: "status",
                    is_show_draft: null,
                    from_date: null,
                    to_date: null,
                },
                currency_name: null,
                brand_id: null,
                item: null,
                customer: null,
                employee: null,
                // filter_by: null,
                json_meta: [
                    [{
                        key: "charset",
                        value: "utf-8",
                    },],
                ],
                page: 0,
            }
        },
        watch: {
            customer: function (val) {
                if (val) {
                    this.filters.customer_id = val.id;
                } else {
                    this.filters.customer_id = null;
                }
            },

        },
        methods: {
            convertTableToExcelReport() {
                this.setReportLog('excel', 'customer installments');
                TableToExcel.convert(document.getElementById("summaryTableDetails"), {
                    name: this.$t("MENU.customer_installments") + '.xlsx',
                    sheet: {
                        name: 'Sheet 1'
                    }
                });
            },

            getRandom() {
                return Math.floor(Math.random() * 10000);
            },

            async getAuthUser() {
                await ApiService.get(`/get_auth`).then((response) => {
                    this.user = response.data.data;
                    this.currency_name = response.data.data.currency_name;
                    if (this.user) {
                        this.user_name = this.user.name;
                    }
                });
            },

            // selectFilter() {
            //     this.getData();
            // },

            doFilter() {
                this.page = 1;
                this.getData();
            },
            resetFilter() {

                this.brand = null;
                this.item = null;
                this.customer = null;
                this.employee = null;
                this.filters.brand_id = null;
                this.filters.item_id = null;
                this.filters.invoice = null;
                this.filters.category_id = null;
                this.filters.inventory_id = null;
                this.filters.from_date = null;
                this.filters.customer_id = null;
                this.filters.employee_id = null;
                this.filters.is_show_draft = null;
                this.filters.to_date = null;
                this.filters.currency_id = null;
                this.filters.filter_by = "status";
                this.getData();

            },
            dataReport() {
                return this.data;
            },
            loadMore() {
                this.page = this.page ? (this.page + 1) : 1;
                this.getData();
            },
            getData() {
                // let filter_by = this.filter_by;
                ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
                    this.data = response.data.data.data;
                    this.dataTotal = response.data.data.total;
                    // console.log(this.data);
                    // console.log(this.dataTotal);

                });
            },

            // getMoreDetails() {
            //     let filter_by = this.filter_by;
            //     ApiService.get(this.mainRoute, {params: {...this.filters,filter_by}}).then((response) => {
            //         this.data_more_details = response.data.data;
            //         let _total_sum = this.$_.sumBy(response.data.data, (row) => {
            //             return row.total.sum_total ? parseFloat(row.total.sum_total) : 0;
            //         });
            //         this.details_total_sum = _total_sum.toFixed(2);
            //     });
            // },


            // getCurrencies() {
            //     ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
            //         this.currencies = response.data.data;
            //     });
            // },


            getCustomers(filter) {
                if (filter && filter.length >= 3)
                    ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
                        this.customers = response.data.data;
                    });
            },

            getStatusList() {
                ApiService.get(this.mainRouteDependency + "/installment_status").then((response) => {
                    this.status_list = response.data.data;
                });
            },

            printData(tableId, type) {
                this.setReportLog('pdf', 'customer installments');
                // let divToPrint = document.getElementById(tableId);
                // let divTitleToPrint = document.getElementById(tableId + 'Title');
                // let newWin = window.open("");
                // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
                // setTimeout(() => {
                //     newWin.print();
                //     newWin.close();
                // }, 100)
              exportPDFInnerAction(tableId , type, this.$t('MENU.customer_installments'), this.$t('MENU.customer_installments'));

            },
            /**
             * register log
             */
            setReportLog(key, description) {
                ApiService.post("reports/system_activity_log/log_type", {
                    key: key,
                    description: description,
                });
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.clients_reports"), route: '/clients/reports'}, {title: this.$t("MENU.customer_installments")}]);

            // this.getCustomers();
            this.getAuthUser();
            // this.getCurrencies();
            this.getStatusList();
        },
    };
</script>
<style>
.html2pdf__overlay{
  opacity: 0 !important;
  z-index: -1000;

}
.selector-export{
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>
